import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import Slider from 'react-slick';

import ic_doc_at_home from '../../assets/Icons/ic_doc_at_home.png';
import ic_need_help_home from '../../assets/Icons/ic_need_help_home.svg';
import medicene_deleviry from '../../assets/Icons/medicene_deleviry.png';
import pathalogy_lab_test from '../../assets/Icons/pathalogy_lab_test.png';
import { LOGIN } from '../../navigation/Constants';
import { Address } from '../../redux/dtos/Address';
import { getRecentUsedLocation, getSelfData, logoutUser } from '../../services/api/DoctorAPI';
import HomeSearchAppBar from './app_bar/HomeAppBar';
import LogoutDialog from './diloage/LogoutDiloag';
import NeedHeplDialogHome from './diloage/NeedHelpDialogHome';
import useStyles from './HomeStyles';

interface RouteParams {
  doctorId: string;
}

const HomeScreen: React.FC = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const classes = useStyles();
  const history = useHistory();
  const params = useParams<RouteParams>();
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [helpDialogOpen, setHelpDialogOpen] = useState(false); // State to control NeedHelpDialog
  const [address, setAddress] = useState<Address | undefined>(undefined);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push(LOGIN);
    } else {
      localStorage.removeItem('selected-symptom');
      localStorage.removeItem('complaint-note');
      getSelfData()
        .then(response => {
          localStorage.setItem('selfData', JSON.stringify(response.data));
          getRecentUsedLocation(response.data.userId)
            .then(responseAddress => {
              setAddress(responseAddress.data);
            })
            .catch(error => {
              console.error('Error:', error);
            });
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }, [history]);

  const onLogout = () => {
    setLogoutDialogOpen(true);
  };

  const onLogoutConfirm = () => {
    localStorage.clear();
    history.push(LOGIN);
  };

  const onChangeLocation = () => {
    // history.push('/select-city');
  };

  const images = [
    'https://atys-images.s3.ap-south-1.amazonaws.com/Services-img/Doctor-Home-visit-S1-.png',
    'https://atys-images.s3.ap-south-1.amazonaws.com/Services-img/Lab-Test-s3-.png',
    'https://atys-images.s3.ap-south-1.amazonaws.com/Services-img/Medicine-Delivery-s2-.png',
  ];

  const onCardClick = (index: number) => {
    if (index === 0) {
      history.push('/choose-address');
    } else if (index === 1) {
      history.push('/lab-test');
    } else {
      history.push('/medicine-delivery');
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
  };

  const firstSlider = [
    ic_doc_at_home,
    medicene_deleviry,
    pathalogy_lab_test,
  ];

  const handleNeedHelpClick = () => {
    setHelpDialogOpen(true);
  };

  return (
    <div className={classes.centerScreen}>
      <div className={classes.container}>
        <HomeSearchAppBar onChangeLocation={onChangeLocation} onLogout={onLogout} address={address} />
        <div style={{ overflowY: 'scroll', justifyItems: 'top', overflowX: 'hidden', scrollbarWidth: 'none' }}>
          <div style={{ marginTop: '20px', marginRight: '10px', marginLeft: '10px' }}>
            <img src='https://atys-images.s3.ap-south-1.amazonaws.com/promtional-banners/Doctor+at+Home.png' alt="banner" className={classes.logo} />
          </div>
          <Grid container alignItems="center" style={{ marginLeft: 20, marginRight: 20, marginTop: 10 }}>
            <Grid item>
              <Typography style={{ fontSize: '18px', padding: '0px', marginTop: 0 }}>
                Services
              </Typography>
            </Grid>
            <Grid item>
              <Typography style={{ color: 'gray', fontSize: '12px', padding: '0px', marginLeft: 4 }} variant="h6">
                {' (Swipe on Active Card)'}
              </Typography>
            </Grid>
          </Grid>
          <div className={classes.horizontalScroll}>
            {images.map((image, index) => (
              <div
                key={index}
                className={classes.card}
                onClick={() => onCardClick(index)}
                style={{ backgroundImage: `url(${image})` }}
              />
            ))}
          </div>
          <div style={{ cursor: 'pointer', marginTop: '20px', marginRight: '10px', marginLeft: '10px', marginBottom: '10px' }}>
            <img src={ic_need_help_home} alt="need help" onClick={handleNeedHelpClick} className={classes.logo} />
          </div>
        </div>
      </div>
      <LogoutDialog
        open={logoutDialogOpen}
        onClose={() => setLogoutDialogOpen(false)}
        onConfirm={onLogoutConfirm}
      />
      <NeedHeplDialogHome
        open={helpDialogOpen}
        onClose={() => setHelpDialogOpen(false)}
      />
    </div>
  );
};

export default HomeScreen;
