import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '430px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    borderRadius: '20px',
    backgroundColor: '#ffffff',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  centerScreen: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    padding: '20px',
    paddingBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      alignItems: 'flex-start',
    },
  },
  scrollableContent: {
    maxHeight: 'calc(100vh - 80px)',
    overflowY: 'scroll',
    width: '100%',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    scrollbarWidth: 'none',
  },
  logo: {
    width: '100%',
  },
  horizontalScroll: {
    display: 'flex',
    overflowX: 'auto',
    padding: '20px 0',
    '& .slick-slide': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  card: {
    flex: '0 0 auto',
    marginLeft: '10px',
    marginRight: '10px',
    height: '300px',
    width: '250px',
    borderRadius: '10px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'transform 0.5s',
    cursor: 'pointer',
  },
  hoeizontalCard: {
    flex: '0 0 auto',
    marginLeft: '10px',
    marginRight: '10px',
    height: '150px',
    width: '360px',
    borderRadius: '10px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'transform 0.5s',
    cursor: 'pointer',
  },
  activeCard: {
    transform: 'scale(1.1)',
  },
}));

export default useStyles;
